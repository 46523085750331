"use client";
import { FaCheckCircle } from "react-icons/fa";

const WhyChooseUsSection = ({
  abovetitle = "Why Choose us",
  title = "Why Partner With us for",
  colouredTitle = "Website Design",
  description = "We, as one of the best website designers in North Carolina and Mumbai, want to help you improve your digital skills, and here's why. We provide website designing in North Carolina and Mumbai and all over the world.",
  data = [
    {
      title: "Unmatched Expertise",
      description:
        "As website developers in North Carolina and Mumbai, Our expertise in web development, e-commerce, and mobile app development, among many others, is unmatched.",
    },
    {
      title: "Tailored Solutions",
      description:
        "We only take on assignments that align with your goals and those of your business so we're committed to tailoring our solutions to your unique circumstances and goals.",
    },
    {
      title: "Creative Brilliance",
      description:
        "In addition to their aesthetic appeal and our Imagination, our designs have been meticulously crafted to attract your ideal customers and effectively convey your message.",
    },
    {
      title: "Technological Mastery",
      description:
        "We, as a Web Designer in North Carolina and Mumbai are technologically savvy and employ cutting-edge web development frameworks and coding strategies to develop solutions that are robust, efficient, and future-proof.",
    },
  ],
}) => {
  return (
    <div>
      <section className="bg-neutral-800 py-8 md:pt-16 lg:py-24 text-white">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-8 lg:mb-20">
            <div className="font-semibold">{abovetitle}</div>
            <h2 className="font-semibold">
              {title}{" "}
              <span className="text-colorOrange-700">{colouredTitle}</span>
            </h2>
            <p>{description}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-10 lg:gap-14 items-start justify-between text-sm divide-y-2 divide-gray-500 text-center relative">
            {data.slice(0, 4).map((point, index) => (
              <div
                key={index}
                className="flex1 place-content-center  justify-between "
              >
                <div className="bg-colorOrange-800  w-20 h-20 rotate-45 m-auto p-4 rounded-lg mt-10 md:-mt-10">
                  <FaCheckCircle
                    size={100}
                    className="w-12 h-12 p-1 text-colorOrange-700 m-auto -rotate-45 place-content-center"
                  />
                </div>

                <div className="mt-8">
                  <h3 className="font-bold">{point.title}</h3>
                  <p
                    className="text-white"
                    dangerouslySetInnerHTML={{ __html: point.description }}
                  ></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUsSection;
