"use client";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CounterSection = ({
  title = "Our Heart is in ",
  colouredTitle = "Every Project",
  description = "We look forward to going to work every day. Our team of devoted digital website designers has been supporting small businesses like yours in distinguishing out and succeeding in the internet arena since 2007.",
  testimonials = [
    {
      name: "Blazing Fast Website",
      testimonial:
        "I couldn't be happier with the website design services. The team delivered an exceptional, blazing-fast website that exceeded my expectations. The user experience is seamless, and the performance is top-notch. Thanks to their expertise, my online presence is stronger than ever!",
      client: "Client",
    },
    {
      name: "Helping You to Run Your Ad Campaigns",
      testimonial:
        "Sumeet and his team created a website that has dramatically improved my ad campaigns. The landing pages are optimized for conversion, and they even provided performance analytics to track results. Their understanding of web design and digital marketing is unparalleled!",
      client: "Client",
    },
    {
      name: "SEO and Social Media Management",
      testimonial:
        "Their website design services have transformed my business. Not only is the website beautifully designed, but their SEO services have helped me rank higher in search results. Their continued social media management keeps my audience engaged, and my traffic has never been better. Highly recommend!",
      client: "Client",
    },
    {
      name: "Dedicated Customer Satisfaction",
      testimonial:
        "From start to finish, the dedication and attention to detail were impressive. The website was delivered on time and looks fantastic. The after-launch support has been exceptional as well. Sumeet is a true professional, and it’s clear he’s passionate about delivering the best results for his clients.",
      client: "Client",
    },
  ],
  data = [
    {
      subtitle: "Performance-Driven",
      description:
        "Our websites not only look stunning but also deliver exceptional user experiences, high-speed performance, and superior SEO.",
      starting: 1,
      limit: 2000,
    },
    {
      subtitle: "Proven Results",
      description:
        "With a portfolio of successful projects, we have a track record of transforming businesses with bespoke web design that converts visitors into customers.",
      starting: 1,
      limit: 2000,
    },
  ],
}) => {
  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section className="bg-neutral-800 py-8 md:py-16 lg:py-24 h-screen2">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <div className="text-left mb-6 md:mb-8">
          <h2 className="font-semibold text-white">
            {title}{" "}
            <span className="text-colorOrange-700">{colouredTitle}</span>
          </h2>
          <p className="lg:mr-36 text-white">{description}</p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between">
          <h3 className="text-white">Testimonials</h3>
          <div className="text-colorOrange-200">
            <a
              className="text-colorOrange-200 text-2xl hover:text-colorAqua-600"
              href="https://www.google.com/search?q=prateeksha+web+design&oq=prateeksha+web+de&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyEAgBEC4YrwEYxwEYgAQYjgUyBggCEEUYPDIGCAMQRRg8MgYIBBBFGDwyBggFEEUYQTIGCAYQRRhBMgYIBxBFGEHSAQg1NDExajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#"
              target="_blank"
            >
              Google Reviews "4.4 Score with 19 reviews"
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-16 items-center">
          <div className="w-full">
            <Slider {...settings}>
              {testimonials.length > 0
                ? testimonials.map((testimonial, index) => (
                    <div
                      className="bg-colorOrange-800 rounded-2xl p-10 min-h-[20rem]"
                      key={index}
                    >
                      <p className="text-white font-bold text-2xl">
                        {testimonial.client || "Client"}
                      </p>
                      <p
                        className="mt-4 text-white"
                        dangerouslySetInnerHTML={{
                          __html: testimonial.testimonial,
                        }}
                      ></p>
                    </div>
                  ))
                : ""}
            </Slider>
          </div>

          <div className="text-left text-white">
            {data.slice(0, 2).map((item, index) => (
              <div key={index} className="mb-8">
                <div className="flex gap-4 items-center mb-3">
                  <div className="text-3xl md:text-4xl lg:text-7xl font-bold text-colorOrange-700">
                    {item.limit}+
                  </div>
                  <p className="font-bold lg:text-2xl">{item.subtitle}</p>
                </div>
                <p
                  className="text-white"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;
