"use client";

import Image from "next/image";
import Link from "next/link";

const IntroSection = ({
  leftposition = true,
  title = "Creative Web Design Solutions",
  colouredTitle = "by Prateeksha",
  imageSrc = "/images/intro/result-driven-website-design.jpg",
  link = "",
  children,
}) => {
  return (
    <section className="bg-white flex flex-col justify-center py-8 md:py-12 lg:py-16">
      <div
        className={`max-w-screen-2xl mx-auto flex flex-col md:flex-row items-center justify-between p-8 ${
          !leftposition ? "md:flex-row-reverse" : ""
        }`}
      >
        <div className="md:w-1/2">
          <div className="">
            <Image
              src={imageSrc}
              alt={`${title} ${colouredTitle}`}
              width={600}
              height={650}
              className="text-right mx-auto"
              quality={75}
              sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
            />
          </div>
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0 pl-0 md:pl-20">
          <h2 className="font-bold text-neutral-800 mb-6">
            {title}{" "}
            <span className="text-colorOrange-700">{colouredTitle}</span>
          </h2>
          {children}
          {link && (
            <Link
              href={link}
              className="mt-4 inline-block bg-gray-800 text-white px-4 py-2 rounded-md"
            >
              Read More
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
